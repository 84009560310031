<template>
  <div class="login">
    <h1>{{ this.$t("auth.header_agree") }}</h1>
    <div class="section">
      <label>{{ $t("auth.title_email") }}</label>
      <div class="value">{{ this.storage.get_user_email() }}</div>
    </div>
    <div class="section">
      <label>{{ $t("auth.title_name") }}</label>
      <div class="value">{{ this.storage.get_user_name() }}</div>
    </div>
    <div class="section">
      <label for="referral_email" :tooltip="this.$t('auth.tootip_referral')"
        >{{ $t("auth.title_referral_email") }} ({{
          $t("auth.optional")
        }})</label
      >
      <input
        id="referral_email"
        type="text"
        placeholder="email@weknowthem.com"
        v-model="referral_email"
      />
      <p class="error">{{ error_email }}</p>
    </div>
    <div class="section">
      <div class="agreement">
        <ul>
          <li>
            <label for="agree_all">{{ $t("auth.label_agree_all") }}</label>
            <input
              type="checkbox"
              id="agree_all"
              value="agree_all"
              @change="click_agree_all"
              v-model="agreed_all"
            />
          </li>
          <li>
            <label for="agree_term">
              <router-link to="/policy/term">{{
                $t("auth.label_agree_term")
              }}</router-link>
              ({{ $t("auth.required") }})
            </label>
            <input
              type="checkbox"
              id="agree_term"
              value="agree_term"
              v-model="agrees"
            />
          </li>
          <li>
            <label for="agree_privacy">
              <router-link to="/policy/privacy">{{
                $t("auth.label_agree_privacy")
              }}</router-link>
              ({{ $t("auth.required") }})
            </label>
            <input
              type="checkbox"
              id="agree_privacy"
              value="agree_privacy"
              v-model="agrees"
            />
          </li>
          <li>
            <label for="agree_notification">
              {{ $t("auth.label_agree_notification") }}
              ({{ $t("auth.optional") }})
            </label>
            <input
              type="checkbox"
              id="agree_notification"
              value="agree_notification"
              v-model="agrees"
            />
          </li>
        </ul>
        <p class="error">{{ error_agree }}</p>
      </div>
    </div>
    <div class="countryInfo">
      <p>{{ this.$t("auth.warning_country1") }}</p>
      <p class="country">
        <img
          class="flag"
          :src="require('@/assets/img/flag/' + this.country_code + '.svg')"
        />
        <span>{{ this.country_name }}</span>
      </p>
      <p>{{ this.$t("auth.warning_country2") }}</p>
    </div>
    <div class="section">
      <button
        class="button red"
        :class="{ inactive: !is_active_button }"
        @click="this.try_agree"
      >
        {{ $t("auth.button_agree") }}
      </button>
      <p class="error">{{ error_button }}</p>
    </div>
    <div class="section text-center">
      <router-link to="/auth/login">
        {{ $t("auth.link_go_to_login") }}
      </router-link>
    </div>
  </div>

  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "Agree",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.agree"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.agree"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],

      user_email: "",
      user_name: "",
      referral_email: "",
      is_active_button: false,

      agrees: [],
      agreed_all: false,
      error_email: "",
      error_agree: "",
      error_button: "",

      country_code: "KOR",
      country_name: "",
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  watch: {
    referral_email: function () {
      this.check_button();
      this.validate_email();
    },
    agrees: function () {
      this.check_agree();
      this.check_button();
      this.validate_agree();
    },
  },
  mounted() {
    // 로그인 안되어 있으면 로그인 페이지로 보내기...
    if (!this.storage.is_logged_in()) {
      self.$router.push("/auth/login");
      return;
    }

    // 사용자 정보 불러오기
    this.load_user_data();

    this.country_code = this.common.get_country_code_from_url();
    this.country_name = this.common.get_country_name_by_code3(
      this.country_code
    );
  },
  methods: {
    load_user_data: function () {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      // 사용자 정보 가져오기
      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/auth/get",
          {
            user_id: self.storage.get_user_id(),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data;
          if (data.result != "OK") {
            self.$swal
              .fire({ text: self.$t("common.error_occur"), icon: "error" })
              .then((result) => {
                result;
                self.$router.back();
              });
            return;
          }

          self.user_email = data.data.user_email;
          self.user_name = data.data.user_name;

          // 이미 동의가 되어 있으면, 메인페이지로 이동
          if (
            data.data.agree_term == "YES" &&
            data.data.agree_privacy == "YES"
          ) {
            // 아바타 설정이 안되어 있으면 설정 페이지로 이동
            if (self.storage.get_user_avatar() == "") {
              self.$router.push("/avatar/customize");
              return;
            }

            self.$router.push("/");
          }
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_user_data

    check_button: function () {
      if (
        this.agrees.includes("agree_term") &&
        this.agrees.includes("agree_privacy")
      ) {
        this.is_active_button = true;

        if (
          this.referral_email.trim() != "" &&
          !this.common.validate_email(this.referral_email)
        ) {
          this.is_active_button = false;
        }
      } else {
        this.is_active_button = false;
      }
    },
    click_agree_all: function () {
      if (this.agreed_all)
        this.agrees = ["agree_term", "agree_privacy", "agree_notification"];
      else this.agrees = [];
    },
    check_agree: function () {
      if (this.agrees.length >= 3) this.agreed_all = true;
      else this.agreed_all = false;
    },

    validate_email: function () {
      if (this.referral_email.trim() == "") {
        this.referral_email = "";
        this.error_email = "";
      } else if (this.common.validate_email(this.referral_email)) {
        this.error_email = "";
      } else {
        // 잘못된 이메일 형식입니다.
        this.error_email = this.$t("auth.error_invalid_email");
      }
    },

    validate_agree: function () {
      if (
        this.agrees.includes("agree_term") &&
        this.agrees.includes("agree_privacy")
      ) {
        this.error_agree = "";
      } else {
        this.error_agree = this.$t("auth.error_agree");
      }
    },

    check_validate: function () {
      this.validate_agree();
    },
    try_agree: function () {
      if (this.processing || !this.is_active_button) return;

      let self = this;

      this.$swal
        .fire({
          text: self.$t("auth.confirm_agree"),
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            self.agree_to_server();
          }
        });
    }, //-- try_agree

    agree_to_server: function () {
      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/auth/agree",
          {
            user_id: self.storage.get_user_id(),
            referral_email: self.referral_email,
            agrees: self.agrees,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (response.data.result == "OK") {
            self.$swal
              .fire({
                text: self.$t("auth.agree_success"),
                icon: "success",
              })
              .then(() => {
                if (self.storage.get_user_avatar() == "") {
                  // 아바타 설정이 안되어 있으면 설정 페이지로 이동
                  self.$router.push("/avatar/customize");
                  return;
                }

                self.$router.push("/");
              });
            return;
          }

          if (response.data.result == "ERR_NO_REFERRAL_EMAIL") {
            self.error_email = self.$t("auth.error_no_referrral_user"); // "해당 이메일 사용자가 존재하지 않습니다.";
            return;
          } else if (response.data.result == "ERR_SELF_EMAIL") {
            self.error_email = self.$t("auth.error_self_referral"); // "자기 자신은 추천인이 될 수 없습니다.";
            return;
          } else {
            self.error_button = self.$t("common.error_occur");
            return;
          }
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- agree_to_server
  },
};
</script>

<style scoped>
.login > .section > .value {
  margin: 0.4rem 0 0 0;
  font-size: 1rem;
}
.countryInfo {
  margin: 0 0 1rem 0;
  padding: 1rem 1.2rem;

  background: #efefef;
  color: #333;
  font-size: 0.875rem;

  border-radius: 0.5rem;
}
.countryInfo > p {
  margin: 0 0 0.6rem 0;
  padding: 0;
}
.countryInfo > p:last-child {
  margin-bottom: 0;
}
.countryInfo > p.country {
  font-weight: 700;
  font-size: 1rem;
  color: #000;
  text-align: center;
}
.countryInfo > p.country > img.flag {
  display: inline-block;
  vertical-align: middle;
  width: 1.6rem;
  margin: 0 0.8rem 0 0;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35);
}
.countryInfo > p.country > span {
  display: inline-block;
  vertical-align: middle;
}
.agreement {
  margin: 32px 0;
}
.agreement > ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.agreement > ul > li {
  position: relative;
  padding: 8px 32px 8px 8px;
  margin: 0;
}
.agreement > ul > li:first-child {
  border-bottom: 1px solid #dedede;
  font-size: 1.2rem;
}
.agreement > ul > li > input[type="checkbox"] {
  position: absolute;
  right: 8px;
  bottom: 50%;
  transform: translate(0, 50%);
  margin: 0;
}

.agreement > ul > li > label a {
  font-size: 1rem !important;
}
</style>
